/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import Button from '../../components/button';

import './index.less';

import IMG_AVATAR_DEFAULT from '../../common/images/avatar-default.png';
import IMG_ME_EXCHANGE from '../../common/images/me-order.png';
import IMG_ME_MESSAGE from '../../common/images/me-leave-message.png';
import IMG_ME_REPORT from '../../common/images/me-safe.png';
import IMG_EDIT from '../../common/images/edit.png';
import IMG_ME_HISTORY from '../../common/images/me-history.png';
import IMG_ME_SETTING from '../../common/images/me-setting.png';

const PageModel = props => {
	const [ userInfo, setUserInfo ] = useState({});
	const getUserInfo = async () => {
		const result = await Request.get({
			url: '/user/get'
		});
		if(!result) return;
		setUserInfo(result.data);
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	const [ flag, setFlag ] = useState(false);
	const [ tempObj, setTempObj ] = useState({});

	const submitHandle = async () => {
		console.log('tempObj', tempObj);
		if(!tempObj.sign) {
			Utils._alert('签名不能为空哦');
			return;
		}

		const result = await Request.post({
			url: '/user/sign',
			data: {
				sign: tempObj.sign
			}
		});
		if(!result) return;
		Utils._alert('签名更新成功');
		setFlag(false);
		delete tempObj.sign;
		setTempObj(tempObj);
		getUserInfo();
	};

	const [ score, setScore ] = useState({ today: 0, total: 0, rest: 0 });
	const getScore = async () => {
		const result = await Request.get({
			url: '/user/score/total'
		});
		if(!result) return;

		setScore({ ...score, ...result.data });
	};
	useEffect(() => {
		getScore();
	}, [123]);

	return (
		<Page {...{ page: 'user', showFooter: true, title: '我的信息' }} {...props}>
			<section className="me-container" style={{ minHeight:window.innerHeight - 50 }}>
				<div className="summary">
					<div className="userinfo">
						<div className="total"><span className="big">{userInfo.nickname}</span><span>{userInfo.department}</span></div>
						{
							userInfo && userInfo.id && <div className="all" onClick={() => {
								props.history.push('/user/setting');
							}}><span>{userInfo && userInfo.sign || '个性十足的签名'}</span><img src={IMG_EDIT} /></div>
						}
					</div>
					<div className="user-cover" style={{ backgroundImage: `url(${userInfo && userInfo.cover || IMG_AVATAR_DEFAULT})` }} />
				</div>
				<div className="me-score">
					<div className="title">
						<span>我的积分</span>
						<div className="exchange-entrance" onClick={() => {
							props.history.push('/store');
						}}>积分兑换</div>
					</div>
					<div className="score-info" onClick={() => {
						props.history.push(`/user/score`);
					}}>
						<div className="item"><label>{score.today}</label><span>今日收益</span></div>
						<div className="item"><label>{score.total}</label><span>累计获得</span></div>
						<div className="item"><label>{score.rest}</label><span>剩余积分</span></div>
					</div>
				</div>
				<div className="me-entrances">
					{
						[
							{ icon: IMG_ME_EXCHANGE, label: '兑换订单', link: '/user/orders' },
							{ icon: IMG_ME_REPORT, label: '上报的问题', link: '/user/reports' },
							{ icon: IMG_ME_MESSAGE, label: '我的留言', link: '/message/list?type=me' },
							{ icon: IMG_ME_SETTING, label: '完善信息', link: '/user/setting' },
							{ icon: IMG_ME_HISTORY, label: '核销记录', link: '/write-off/record' },
						].map((item, idx) => {
							return (
								<div className="item" key={idx} onClick={() => {
									if(item.link) {
										props.history.push(item.link);
									}else if(item.clickHandle && typeof item.clickHandle == 'function') {
										item.clickHandle();
									}
								}}><label>{item.label}</label><img src={item.icon} /></div>
							)
						})
					}
				</div>
				{
					userInfo && userInfo.type == 2 && <div className="exchange-worker-account" onClick={() => {
						window.location.href = '/worker/home';
					}}>切换账号</div>
				}
			</section>

			{/*{
				!!flag && <section className="self-modal-container">
					<section className="self-modal-wrap">
						<div className="title">编辑个性十足的签名<div className="close" onClick={() => {
							setFlag(false);
							delete tempObj.sign;
							setTempObj(tempObj);
						}} /></div>
						<div className="input-wrap"><input placeholder="输入字数不超过10个字" defaultValue={tempObj.sign || undefined} maxLength="10" onChange={e => {
							console.log('e', e, e.target.value);
							setTempObj({
								...tempObj,
								sign: e.target.value
							});
						}} /></div>
						<Button
							disabled={!tempObj.sign}
							onClick={submitHandle}
						/>
					</section>
				</section>
			}*/}
		</Page>
	);
};

export default PageModel;
