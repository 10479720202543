/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import Button from '../../components/button';

import './index.less';

import IMG_AVATAR_DEFAULT from '../../common/images/avatar-default.png';
import IMG_ME_EXCHANGE from '../../common/images/me-exchange.png';
import IMG_ME_MESSAGE from '../../common/images/me-message.png';
import IMG_ME_REPORT from '../../common/images/me-report.png';
import IMG_EDIT from '../../common/images/edit.png';
import IMG_CATEGORY from "../../common/images/category.png";
import IMG_TRIAL from "../../common/images/trial.png";
import * as Const from "../../common/const";
import {user} from "../../common/utils";

const PageModel = props => {
	const [ userInfo, setUserInfo ] = useState({});
	const getUserInfo = async () => {
		const result = await Request.get({
			url: '/user/get'
		});
		if(!result) return;
		setUserInfo(result.data);
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	const [ flag, setFlag ] = useState(false);
	const [ tempObj, setTempObj ] = useState({});

	const submitHandle = async () => {
		const result = await Request.post({
			url: '/user/set',
			data: userInfo
		});
		if(!result) return;
		Utils._alert('更新成功');
		getUserInfo();
	};

	const [ departmentFlag, setDepartmentFlag ] = useState(false);

	return (
		<Page title="设置">
			<section className="me-setting-container" style={{ minHeight:window.innerHeight }}>
				<div className="category-wrap">
					<div className="label"><img src={IMG_CATEGORY} /><span>姓名</span></div>
					<div className="value"><span><input placeholder="输入姓名" defaultValue={userInfo.nickname} onChange={e => {
						setUserInfo({ ...userInfo, nickname: e.target.value });
					}} /></span></div>
				</div>
				<div className="category-wrap" onClick={() => {
					setDepartmentFlag(false);
				}}>
					<div className="label"><img src={IMG_CATEGORY} /><span>所在村居</span></div>
					<div className="value"><span style={{ marginLeft: 10, marginRight: 10 }}>{userInfo.department || '选择村居'}</span></div>
					{/* <img className="trial" src={IMG_TRIAL} /> */}
				</div>
				<div className="category-wrap">
					<div className="label"><img src={IMG_CATEGORY} /><span>手机号码</span></div>
					<div className="value"><span><input maxLength={11} placeholder="请输入手机号码" defaultValue={userInfo.mobile} onChange={e => {
						setUserInfo({ ...userInfo, mobile: e.target.value });
					}} /></span></div>
				</div>
				<div className="category-wrap">
					<div className="label"><img src={IMG_CATEGORY} /><span>个性签名</span></div>
					<div className="value"><span><input maxLength={11} placeholder="输入字数不超过10个字" defaultValue={userInfo.sign} onChange={e => {
						setUserInfo({ ...userInfo, sign: e.target.value });
					}} /></span></div>
				</div>

				<div className="button-wrap"><Button style={{margin: 15}} onClick={submitHandle}/></div>
			</section>

			{
				departmentFlag && <section className="category-container">
					<section className="category-wrap">
						<div className="title">
							<span>选择村居</span>
							<div className="close" onClick={() => { setDepartmentFlag(false); }} />
						</div>
						<div className="list-wrap">
							<div className="clist">
								{
									Const.departmentMap && Const.departmentMap.length > 0 && Const.departmentMap.map((item, idx) => {
										const selected = item == userInfo.department;
										return (
											<div className={`item ${selected && 'selected' || ''}`} key={idx} onClick={() => {
												setUserInfo({ ...userInfo, department: item });
												setDepartmentFlag(false);
											}}>{item}</div>
										)
									})
								}
							</div>
						</div>
					</section>
				</section>
			}
		</Page>
	);
};

export default PageModel;
